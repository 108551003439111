import React, { useState } from 'react'
import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { Link } from '@material-ui/core'
import ButtonLarge from '../ButtonLarge'

import { useStyles } from './scripts'
import productList from '../../data/product-list-minicart.json'
import './styles.scss'

function MiniCart({ cartImg }) {
  const [quantityState, setQuantityState] = useState(0)

  const sumTotalProduct = productList.length

  const classes = useStyles()
  const [state, setState] = useState({
    right: false,
  })

  const handleClickAdd = id => {
    setQuantityState(quantityState + 1)
  }
  const handleClickRemove = () => {
    if (quantityState >= 1) setQuantityState(quantityState - 1)
  }
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <section className="minicart-container">
        <div className="minicart-header">
          <h1>Sacola</h1>
          <p>{sumTotalProduct} Itens</p>
          <Link onClick={toggleDrawer(anchor, false)}>
            <img src="images/close-minicart.svg" alt="Close" />
          </Link>
        </div>
        <Divider />
        <div>
          <ul className="product-list-minicart">
            {productList.map(product => {
              return (
                <li key={product.id}>
                  <img src={product.path} alt="" />
                  <div className="price-in-list">
                    <p>{product.name}</p>
                    <span>R$ 49,90</span>
                  </div>
                  <div className="select-qty-in-list">
                    <Link>
                      <img
                        src="images/-.svg"
                        alt="remove"
                        onClick={handleClickRemove}
                      />
                    </Link>
                    <input
                      type="text"
                      value={quantityState}
                      onChange={e => {
                        setQuantityState(parseInt(e.nativeEvent.data))
                      }}
                    />
                    <Link>
                      <img
                        src="images/+.svg"
                        alt="add"
                        onClick={handleClickAdd}
                      />
                    </Link>
                  </div>
                  <div className="remove-prod-from-list">
                    <Link onClick={toggleDrawer(anchor, false)}>
                      <img src="images/remove-prod.svg" alt="Close" />
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
      <div className="minicart-footer">
        <div className="minicart-freight">
          <p>
            Faltam R$ xx,xx para você <span>Ganhar Frete Grátis</span>
          </p>
        </div>
        <div className="minicart-card-total">
          <div className="minicart-total-price">
            <p>Total: R$ 149,00</p>
            <p>até 3x de R$ 49,90 sem juros</p>
          </div>
          <div className="minicart-button-checkout">
            <ButtonLarge
              backgroundColor="#32917B"
              textColor="#fff"
              fontSize="12px"
            >
              Finalizar compra
            </ButtonLarge>
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {['right'].map(anchor => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <img src={cartImg} alt="Carrinho" />
            <p>{sumTotalProduct}</p>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
export default MiniCart
