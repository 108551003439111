import React from 'react'
import Carousel from 'react-elastic-carousel'
import { Grid } from '@material-ui/core'
import './styles.scss'
import BuyBox from '../BuyBox'

function ProductSection() {
  return (
    <Grid container spacing={4} className="product-section">
      <Grid item sm={1}>
        <Carousel
          verticalMode
          itemsToShow={4}
          pagination={false}
          className="carousel"
        >
          <img src="images/prod02.svg" alt="prod1" />
          <img src="images/prod02.svg" alt="prod1" />
          <img src="images/prod02.svg" alt="prod1" />
          <img src="images/prod02.svg" alt="prod1" />
          <img src="images/prod02.svg" alt="prod1" />
          <img src="images/prod02.svg" alt="prod1" />
        </Carousel>
      </Grid>
      <Grid item sm={5}>
        <figure>
          <img src="images/img-product-big.svg" alt="prod1" />
        </figure>
      </Grid>
      <Grid item sm={6}>
        <section className="product-details">
          <BuyBox />
        </section>
      </Grid>
    </Grid>
  )
}

export default ProductSection
