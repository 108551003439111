import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormFooter from '../../FormFooter'
import './styles.scss'

function FooterMobile() {
  return (
    <section className="footer-mobile-content">
      <div className="accordions">
        <Accordion className="accord-inst">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Institucional</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li>A Marca</li>
              <li>Lojas</li>
              <li>Contato</li>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Informações</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li>Formas de Pagamento</li>
              <li>Trocas e Devoluções</li>
              <li>Cuidados Com o Produto</li>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Conheça</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li>Franquias e Multimarcas</li>
              <li>Trabalhe com a Gente</li>
              <li>Procon-RJ</li>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="images">
        <ul>
          <li>
            <img src="images/facebook.svg" alt="" />
          </li>
          <li>
            <img src="images/instagram.svg" alt="" />
          </li>
          <li>
            <img src="images/pinterest.svg" alt="" />
          </li>
        </ul>
        <ul>
          <li>
            <img src="images/vtex-pci-200.svg" alt="" />
          </li>
          <li>
            <img src="images/selo-ebit.svg" alt="" />
          </li>
        </ul>
      </div>
      <FormFooter formDiv="form-div-mobile" formTitle="form-title-mobile" />
    </section>
  )
}

export default FooterMobile
