import React from 'react'
import MiniCart from '../../MiniCart'
import MenuMobile from '../MenuMobile'
import './styles.scss'

function HeaderMobile() {
  return (
    <section>
      <ul className="links-header-mobile">
        <li>
          <MenuMobile />
        </li>
        <li className="logo-mobile">
          <img src="images/logo.svg" alt="" />
        </li>
        <ul className="links-search-cart">
          <li>
            <img src="images/search.svg" alt="" />
          </li>
          <li>
            <MiniCart cartImg="images/bag2.svg" />
          </li>
        </ul>
      </ul>
    </section>
  )
}

export default HeaderMobile
