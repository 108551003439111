import React from 'react'

import './styles.scss'

function ButtonMedium({ children, textColor, backgroundColor }) {
  return (
    <button
      className="btn-medium"
      style={{ backgroundColor: backgroundColor, color: textColor }}
      type="submit"
    >
      {children}
    </button>
  )
}

export default ButtonMedium
