import React from 'react'
import ButtonMedium from '../ButtonMedium'
import TextField from '@material-ui/core/TextField'
import './styles.scss'

function FormFooter({ formDiv, formTitle }) {
  return (
    <div className={formDiv}>
      <h3 className={formTitle}>Assine nossa News</h3>
      <form>
        <TextField id="name" label="Nome" />
        <div>
          <TextField id="email" label="E-mail" />
          <ButtonMedium textColor="#fff" backgroundColor="#DE8F75">
            Enviar
          </ButtonMedium>
        </div>
      </form>
    </div>
  )
}

export default FormFooter
