import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import ButtonLarge from '../ButtonLarge'
import { useStyles } from './scripts'
import { Link } from '@material-ui/core'
import './styles.scss'

function ModalAddToCart() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <ButtonLarge
        textColor="#FFFFFF"
        backgroundColor="#32917B"
        className="product-button-buy"
        onClick={handleOpen}
      >
        Adicionar à sacola
      </ButtonLarge>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Link onClick={handleClose} className="button-modal-close">
              <img src="images/close-minicart.svg" alt="Close" />
            </Link>
            <img
              src="images/img-product-big.svg"
              alt="Product"
              className="image-product-modal"
            />
            <h2 className="product-addded-to-cart">
              Produto adicionado com sucesso!
            </h2>
            <ButtonLarge
              textColor="#FFFFFF"
              backgroundColor="#32917B"
              className="product-button-buy"
              fontSize={14}
            >
              Finalizar Compra
            </ButtonLarge>
            <p id="transition-modal-description">Continuar Comprando</p>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default ModalAddToCart
