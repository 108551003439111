import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    width: 'min-content',
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 6, 6, 6),
    outline: 'none',
  },
}))
