import React, { useState, useEffect } from 'react'
import sizes from '../../data/sizes.json'
import { Link } from '@material-ui/core'
import productsColor from '../../data/product-color.json'
import ProductButtonColor from '../../components/ProductButtonColor'
import ModalAddToCart from '../../components/ModalAddToCart'
import MediaQuery from 'react-responsive'
import './styles.scss'

function BuyBox() {
  const [sizeState, setSizeState] = useState([])
  const [sizeIdSelectedState, setSizeIdSelected] = useState(4)
  const [sizeSelectedState, setSizeSelected] = useState(37)
  useEffect(() => {
    setSizeState(sizes)
  }, [])

  const handleButtonClick = e => {
    setSizeIdSelected(e.target.id)
    setSizeSelected(e.target.innerText)
  }
  return (
    <section>
      <MediaQuery minDeviceWidth={1030}>
        <section>
          <div>
            <h1 className="product-title">Rasteira Tira Dedo</h1>
            <span className="product-code">RT 0568 | 03.07.0653</span>
          </div>
          <div className="product-prices">
            <span className="price">R$ 69,00</span>
            <span> | </span>
            <span className="discount">R$ 55,20</span>
            <p className="parcels">Ou 6x de R$ 9,20</p>
          </div>
        </section>
      </MediaQuery>
      <div className="product-colors">
        <p className="color-selected">
          <span>Cor: </span>
          <span>(Fucsia)</span>
        </p>
        <ul className="colors">
          {productsColor.map(product => {
            return (
              <li key={product.id}>
                <ProductButtonColor
                  id={product.id}
                  color={product.color}
                  height={22}
                  width={22}
                />
              </li>
            )
          })}
        </ul>
      </div>
      <div className="product-sizes">
        <ul>
          <li>
            Tamanho: <span>({sizeSelectedState})</span>
          </li>
          <li>Guia de medidas</li>
        </ul>
        <ul className="sizes">
          {sizeState.map(product => {
            return (
              <li
                key={product.id}
                id={product.id}
                onClick={handleButtonClick}
                className={
                  sizeState[sizeIdSelectedState].id === product.id
                    ? 'active'
                    : null
                }
              >
                <Link id={product.id}>
                  <span id={product.id}>{product.size}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      <MediaQuery maxDeviceWidth={1030}>
        <section className="card-checkout">
          <div className="product-prices">
            <span className="price">R$ 69,00</span>
            <span className="discount">R$ 55,20</span>
            <p className="parcels">Ou 6x de R$ 9,20</p>
          </div>
          <div className="button-buy">
            <ModalAddToCart />
          </div>
        </section>
      </MediaQuery>
      <MediaQuery minDeviceWidth={1030}>
        <div className="button-buy">
          <ModalAddToCart />
        </div>
      </MediaQuery>
      <div className="short-description">
        <h3>Descrição</h3>
        <p>
          Rasteira em atanado soft com tira no dedo e fechamento de fivela.
          Possui sola sempre na cor do cabedal.
        </p>
      </div>
    </section>
  )
}

export default BuyBox
