import React from 'react'

import { Grid } from '@material-ui/core'
import './styles.scss'
import MiniCart from '../MiniCart'

function Category() {
  return (
    <Grid container spacing={3} className="container">
      <Grid item xs>
        <ul className="link-access">
          <li>Entrar</li>
          <li>|</li>
          <li>Cadastrar-se</li>
        </ul>
      </Grid>
      <Grid item xs={7}>
        <ul className="category">
          <li>Sapatos</li>
          <li>Bolsas</li>
          <li>Acessórios</li>
          <li>Off</li>
        </ul>
      </Grid>
      <Grid item xs>
        <ul className="right-content">
          <li className="search-field">
            <input type="text" placeholder="Busca" />
          </li>
          <li>
            <MiniCart cartImg="images/cart.svg" />
          </li>
        </ul>
      </Grid>
    </Grid>
  )
}

export default Category
