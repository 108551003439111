import React from 'react'
import FormFooter from '../FormFooter'

import './styles.scss'

function Footer() {
  return (
    <>
      <section className="main-footer">
        <div className="images">
          <ul>
            <li>
              <img src="images/facebook.svg" alt="" />
            </li>
            <li>
              <img src="images/instagram.svg" alt="" />
            </li>
            <li>
              <img src="images/pinterest.svg" alt="" />
            </li>
          </ul>
          <ul>
            <li>
              <img src="images/vtex-pci-200.svg" alt="" />
            </li>
            <li>
              <img src="images/selo-ebit.svg" alt="" />
            </li>
          </ul>
        </div>
        <div>
          <h3>Institucional</h3>
          <ul>
            <li>A Marca</li>
            <li>Lojas</li>
            <li>Contato</li>
          </ul>
        </div>
        <div>
          <h3>Informações</h3>
          <ul>
            <li>Formas de Pagamento</li>
            <li>Trocas e Devoluções</li>
            <li>Cuidados Com o Produto</li>
          </ul>
        </div>
        <div>
          <h3>Conheça</h3>
          <ul>
            <li>Franquias e Multimarcas</li>
            <li>Trabalhe com a Gente</li>
            <li>Procon-RJ</li>
          </ul>
        </div>
        <FormFooter formTitle="title-news" formDiv="newsletter" />
      </section>
    </>
  )
}

export default Footer
