import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Container } from '@material-ui/core'
import RelatedProducts from '../../components/RelatedProducts'
import HeaderMobile from '../../components/Mobile/HeaderMobile'
import ProductSectionMobile from '../../components/Mobile/ProductSectionMobile'
import ProductSection from '../../components/ProductSection'
import FooterMobile from '../../components/Mobile/FooterMobile'
import MediaQuery from 'react-responsive'

import './styles.scss'

function Home() {
  return (
    <Container maxWidth="lg">
      <MediaQuery maxDeviceWidth={1030}>
        <HeaderMobile />
        <ProductSectionMobile />
        <RelatedProducts />
        <FooterMobile />
      </MediaQuery>

      <MediaQuery minDeviceWidth={1030}>
        <Header />
        <ProductSection />
        <RelatedProducts />
        <hr className="divisor" />
        <Footer />
      </MediaQuery>
      <div className="last-footer">
        <div>
          <p>
            Via Mia | V. Milano Centro Comercio de Bolsas Eireli - EPP. Av.das
            Américas, 500 - bloco 20, loja 126 - Barra da Tijuca - Rio de
            Janeiro - RJ - CEP: 22640-100 CNPJ: 05.292.288/0002-10 - I.E:
            86.732.548 - E-mail: ecommerce@viamia.com.br
          </p>
        </div>
        <img src="images/logo.svg" alt="Logo" />
      </div>
    </Container>
  )
}

export default Home
