import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import BuyBox from '../../BuyBox'
import { responsive } from './scripts'
import './styles.scss'

function ProductSection() {
  return (
    <section>
      <div className="product-informations-mobile">
        <h1 className="product-title-mobile">Rasteira Tira Dedo</h1>
        <span className="product-code-mobile">RT 0568 | 03.07.0653</span>
      </div>

      <Carousel
        responsive={responsive}
        infinite={true}
        showDots={true}
        arrows={false}
        className="product-section-mobile"
      >
        <figure>
          <img src="images/related-prod-3.svg" alt="" />
        </figure>
        <figure>
          <img src="images/related-prod-3.svg" alt="" />
        </figure>
        <figure>
          <img src="images/related-prod-3.svg" alt="" />
        </figure>
        <figure>
          <img src="images/related-prod-3.svg" alt="" />
        </figure>
      </Carousel>
      <BuyBox />
    </section>
  )
}

export default ProductSection
