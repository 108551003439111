import React from 'react'
import Category from '../Category'
import { Breadcrumbs, Typography, Link, Grid } from '@material-ui/core'
import './styles.scss'
function Header() {
  return (
    <section>
      <div className="header-logo">
        <img src="images/logo.svg" alt="Logo" />
      </div>
      <Category />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link color="inherit" href="/" onClick={() => ''}>
              Home
            </Link>
            <Typography color="textPrimary">Sapatos</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </section>
  )
}

export default Header
