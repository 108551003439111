export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1300, min: 920 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 920, min: 0 },
    items: 1,
  },
}
