import React from 'react'
import './styles.scss'

function ButtonLarge({
  children,
  backgroundColor,
  textColor,
  onClick,
  fontSize,
}) {
  return (
    <button
      className="btn-large"
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        fontSize: fontSize,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default ButtonLarge
