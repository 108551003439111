import React from 'react'
import './styles.scss'

function ProductButtonColor({ id, color, width, height }) {
  return (
    <button
      className="product-button-color"
      id={id}
      style={{ backgroundColor: color, width: width, height: height }}
    ></button>
  )
}

export default ProductButtonColor
