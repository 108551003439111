import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { responsive } from './scripts'
import './styles.scss'
import products from '../../data/related-products.json'
import ProductButtonColor from '../ProductButtonColor'

export function RelatedProductsItem({ prod }) {
  return prod.colors.map(color => {
    return (
      <li key={color}>
        <ProductButtonColor height={13} width={13} color={color} />
      </li>
    )
  })
}

function RelatedProducts() {
  return (
    <section className="related-products">
      <div className="related-products-title">
        <h2>Quem viu, viu também</h2>
      </div>
      <Carousel
        responsive={responsive}
        className="related-products-img"
        infinite={true}
        arrows={true}
      >
        {products.map(product => {
          return (
            <figure key={product.id}>
              <img src={product.path} alt={product.originalName} />
              <div>
                <ul className="related-product-informations">
                  <li>R$ {product.price}</li>
                  <li>
                    <ul className="related-product-colors">
                      <RelatedProductsItem prod={product} />
                    </ul>
                  </li>
                </ul>
              </div>
            </figure>
          )
        })}
      </Carousel>
    </section>
  )
}

export default RelatedProducts
